<template>
  <div>
    <div class="flex flex-wrap p-3">

      <p
        :class="`${complianceDetails.status.toLowerCase()} rounded-full text-sm text-center px-3 py-1 inline-block font-semibold`">
        {{ getStatusData(complianceDetails.status) }}
      </p>

      <div class="w-full mt-5 hidden lg:block">
        <p> {{ `${complianceQuarter} ${complianceYear}` }} </p>
        <p class="mt-2 text-3xl font-bold"> {{ complianceDetails.clinicName }} </p>
        <p class="mt-2"> Step 2 of 2 - Clinic tour video upload </p>
        <p class="text-sm"> Files supported for upload: MOV, MP4 </p>
        <vs-divider />
      </div>

      <div class="w-full mt-5 lg:hidden">
        <p> {{ `${complianceQuarter} Compliance` }} </p>
        <p class="mt-2 text-3xl font-bold"> {{ complianceDetails.clinicName }} </p>
        <p class="mt-2"> Step 2 of 2 - Clinic tour video upload </p>
        <p class="text-sm"> Files supported for upload: MOV, MP4 </p>
        <vs-divider />
      </div>

      <vs-popup class="overflow-hidden" title="Image Preview" :active.sync="popupImageActivo">
        <div class="w-full">
          <div class="w-full">
            <img class="w-full object-fit" :key="imagePreviewSrc" :src="imagePreviewSrc" />
          </div>
          <div class="w-full flex justify-end">
            <vs-button size="lg" color="danger" class="text-right mt-5" @click="removePreview">Remove</vs-button>
          </div>
        </div>
      </vs-popup>

      <div class="w-full">

        <div slot="header">
          <p class="text-lg lg:text-2xl font-semibold">
            How to complete your clinic tour
          </p>
        </div>

        <div class="my-3">
          <iframe title="vimeo-player" src="https://player.vimeo.com/video/733855330?h=0b8760a19b"
            class="rounded w-full hidden lg:block" frameborder="0" allowfullscreen></iframe>
          <iframe title="vimeo-player" src="https://player.vimeo.com/video/733855330?h=0b8760a19b"
            class="rounded w-full lg:hidden" frameborder="0" allowfullscreen></iframe>
        </div>

        <vs-divider />

        <div slot="header">
          <p class="text-lg lg:text-2xl font-semibold">
            Upload your clinic tour
          </p>
        </div>
        
        <div class="w-full">
          <p>Ensure your video covers all of these areas:</p>

          <div v-if="errors.includes('videoTour')"
            class="con-text-validation span-text-validation text-sm font-medium px-0 mt-1 mb-2-danger vs-input--text-validation-span">
            <span class="span-text-validation text-sm font-medium px-0 mt-1 mb-2">Upload at least one(1) clinic tour</span>
          </div>

          <vs-list>
            <vs-list-item subtitle="Fridge" icon="radio_button_checked" />
            <vs-list-item subtitle="Drug storage" icon="radio_button_checked" />
            <vs-list-item subtitle="Sharps bin" icon="radio_button_checked" />
            <vs-list-item subtitle="Locks" icon="radio_button_checked" />
            <vs-list-item subtitle="General cleanliness; floor, bed etc" icon="radio_button_checked" />
            <vs-list-item subtitle="Maximum file upload of 200mb" icon="radio_button_checked" />
          </vs-list>
          
          <div class="flex justify-center lg:hidden">
            <div class="vs-con-loading__container relative">

              <label class="upload-btn text-center px-10 py-2 flex items-center rounded-lg">
                <span>
                  <span 
                    class="vs-button-backgroundx vs-button--background"
                    style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"
                  ></span>
                  <span> Upload </span>
                  <input type="file" accept="video/mp4,video/x-m4v,video/*" @change="uploadImage($event, 'videoTour')" id="fridge-file-input" hidden multiple />
                  <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                </span>
              </label>

            </div>
            <div v-if="errors.includes('maxVidSize')"
              class="con-text-validation span-text-validation text-sm font-medium px-0 mt-1 mb-2-danger vs-input--text-validation-span lg:hidden absolute mt-12">
              <span class="span-text-validation text-sm font-medium px-0 mt-1 mb-2">Upload size must be 200mb or less.</span>
            </div>
          </div>
          <div class="flex flex-wrap mt-8">
            <div :key="index" v-for="(video, index) in videos.videoTour" class="py-2 ">
              <video class="rounded hidden lg:block" width="300" height="250" controls preload="auto">
                <source :src="video.videoLink" />
              </video>
              <video width="100%" height="250" class="rounded lg:hidden" controls preload="auto">
                <source :src="video.videoLink" />
              </video>
              <div class="flex items-center justify-between mt-2">
                <p class="text-sm">{{ formatDate(video.createdAt) }}</p>
                <vs-button color="danger" class="text-right" @click="deleteVideoPopup(video)">Remove
                </vs-button>
              </div>
            </div>
          </div>

          <div class="hidden lg:flex">
            <div class="vs-con-loading__container overflow-visible relative">
              <label class="upload-btn text-center px-10 py-2 flex items-center rounded-lg">
                <span>
                  <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                  <span> Upload </span>
                  <input type="file" accept="video/mp4,video/x-m4v,video/*" @change="uploadImage($event, 'videoTour')" id="fridge-file-input" hidden multiple />
                    <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                </span>
              </label>
            </div>
          </div>
          <div v-if="errors.includes('maxVidSize')"
            class="con-text-validation span-text-validation text-sm font-medium px-0 mt-1 mb-2-danger vs-input--text-validation-span hidden lg:flex">
            <span class="span-text-validation text-sm font-medium px-0 mt-1 mb-2">Upload size must be 200mb or less.</span>
          </div>
        </div>

      </div>
      <div class="w-full flex justify-end items-center mt-6 mb-4 ">
        <vs-button size="lg" color="primary" @click="saveImages">Save</vs-button>
      </div>
    </div>

    <vs-divider />

    <div class="flex justify-center">
      <div class="text-center">
        <p class="font-semibold">Need assistance?</p>
        <p class="mb-2">Speak to one of our compliance specialists</p>
        <vs-button type="border" color="primary" @click="redirectToUploadImage">Schedule a video call</vs-button>
      </div>
    </div>
    
    <vs-popup class="holamundo" title="" :active.sync="deleteActive">
      <div class="p-6">
        <p class="text-center text-lg font-medium mb-3">
          Are you sure you want to remove this video
        </p>
        <div class="flex items-center justify-between space-x-4 mt-2">
          <vs-button size="lg" color="danger" @click="deleteActive = false" class="flex-1">Cancel </vs-button>
          <vs-button size="lg" @click="removeVideo" class="flex-1">Yes </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import _ from "lodash";

export default {
  inject: ['getRoleRouteName'],
  data() {
    return {
      popupImageActivo: false,
      deleteActive: false,
      imagePreviewSrc: "",
      status: "In Review",
      clinicId: "",
      complianceQuarter: "",
      complianceYear: "",
      complianceDetails: {},
      errors: [],
      videos: {
        videoTour: [],
      },
      selectedVideo: null,
    };
  },
  methods: {
    ...mapActions("monthlyCompliance", [
      "udpatebookingDetails",
      "uploadFiles",
      "updateVideos",
      "fetchDetails",
      "startCompliance",
    ]),

    ImagePreview(src, imagefor = "") {
      this.popupImageActivo = true;
      this.imagePreviewSrc = { src: src, imagefor: imagefor };
    },
    formatDate(value) {
      const date = moment(value);
      return date.format("MMMM DD YYYY");
    },
    removePreview() {
      const index = _.findIndex(this.images[this.imagePreviewSrc.imagefor], [
        "photoLink",
        this.imagePreviewSrc.src,
      ]);
      this.images[this.imagePreviewSrc.imagefor].splice(index, 1);
      if (this.images[this.imagePreviewSrc.imagefor].length === 0) {
        this.errors.push(this.imagePreviewSrc.imagefor);
      }
      this.imagePreviewSrc = "";
      this.popupImageActivo = false;
    },
    async getDetails(clinicId) {
      const quarters = ['January - March', 'April - June', 'July - September', 'October - December']
      const res = await this.fetchDetails({ clinicId: clinicId });

      if (res.data.data.length > 0) {
        this.complianceDetails = res.data.data[0];
        const complianceCreatedDate = moment(res.data.data[0].createdAt).utc()
        this.complianceQuarter = quarters[complianceCreatedDate.quarter() - 1]
        this.complianceYear = complianceCreatedDate.format(
          "YYYY"
        );
        this.videos.videoTour = _.filter(res.data.data[0].uploadedVideos, [
          "group",
          "videoTour",
        ]).map((item) => item);
      }
    },
    async saveImages() {
      try {
        if (this.videos.videoTour.length === 0) {
          this.errors = _.pullAll(
            [this.videos.videoTour.length === 0 ? "videoTour" : undefined],
            [undefined]
          );
          this.$vs.notify({
            title: "Failed",
            text: "Upload clinic tour. Try again!",
            color: "danger",
          });
        } else {
          this.$vs.loading();
          const videoTour = this.videos.videoTour;
          const data = {
            payload: {
              uploadedVideos: [...videoTour],
            },
            clinicId: this.clinicId,
          };
          const res = await this.updateVideos(data);
          await this.startCompliance(this.clinicId);
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Success",
            text: "Monthly Compliance successfully saved.",
            color: "success",
          });
          this.$router.push({
            name: this.getRoleRouteName("Compliance"),
          });
        }
      } catch (error) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: "Failed to update Monthly Compliance. Try again!",
          color: "danger",
        });
      }
    },
    async uploadImage(event, imagefor = "") {
      try {
        if (event.target.files[0]["size"] < 209715200) {
          if (event.target.files[0]["type"].startsWith("video")) {
            this.$vs.loading({
              text: "Please wait while we're uploading your file(s)...",
            });
            this.errors = _.pullAll(
              [event.target.files[0]["size"] < 209715200 && undefined],
              [undefined]
            );
            let files = new FormData();
            const fileArray = Object.keys(event.target.files).map(
              (item) => item
            );
            fileArray.forEach((item) => {
              files.append(`files[${item}]`, event.target.files[item]);
            });

            const res = await this.uploadFiles(files);
            this.videos[imagefor] = [
              ...this.videos[imagefor],
              ...res.data.map((item) => {
                item.videoLink = item.Location;
                item.group = imagefor;
                return item;
              }),
            ];
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        } else {
          this.errors = _.pullAll(
            [
              event.target.files[0]["size"] > 209715200
                ? "maxVidSize"
                : undefined,
            ],
            [undefined]
          );
          this.$vs.notify({
            title: "Upload Failed",
            text: "File exceeds maximum file size",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      } catch (err) {
        this.$vs.loading.close();
        if (err && err.status === 415) {
          this.$vs.notify({
            title: "Upload Failed",
            text: "File Format Not Supported",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        } else {
          this.$vs.notify({
            title: "Upload Failed",
            text: "Please try again later.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      }
    },
    deleteVideoPopup(video) {
      this.deleteActive = true;
      this.selectedVideo = video.videoLink;
    },
    removeVideo() {
      const index = _.findIndex(this.videos.videoTour, [
        "videoLink",
        this.selectedVideo,
      ]);
      this.videos.videoTour.splice(index, 1);
      this.imagePreviewSrc = null;
      this.deleteActive = false;
    },
    redirectToUploadImage() {
      this.$router.push({
        name: this.getRoleRouteName("ComplianceStepBooking"),
        params: { clinicId: this.clinicId },
      });
    },
    getStatusData(data) {
      const status = {
        upNext: 'Up Next',
        failed: 'Missed',
        skipped: 'Skipped',
        inReview: 'Ready for Review',
        changeRequested: 'Change Requested',
        completed: 'Completed',
      }

      return status[data];
    },
  },
  watch: {
    deleteActive(newVal, oldVal) {
      if (newVal !== oldVal && newVal === false) {
        this.imagePreviewSrc = null;
      }
    },
  },
  created: async function () {
    this.clinicId = this.$route.params.clinicId;
    await this.getDetails(this.clinicId);
  },
};
</script>

<style lang="scss">

iframe {
  background-color: #efefef;
  height: 18rem;
}


.upload {
  &-btn {
    background-color: #eef9f3;
    border: 1px solid #467969;
  }
}

.con {
  &-vs {
    &-popup {
      &--content {
        padding: 1rem !important;
      }
    }
   
  }
}

.vs{
  &-list {
    padding: 0 !important;

    &--item {
      padding: 8px 0 !important;
    }

    &-titles {

    }

    &--subtitle {
      font-size: 1rem !important;
    }
  }
  &-popup {
    &--content {
      padding: 1rem !important;
    }
  }
}

.vs-collapse-item--content {
  max-height: none !important;
}

.upnext,
.inreview {
  background-color: #E5F8EE;
  color: #84CDB8;
}

.skipped {
  background-color: #E2E8E6;
  color: #074230;
}

.failed {
  background-color: #FBE4E4;
  color: #EA5455;
}

.changerequested {
  background-color: #FF9F43;
  color: #FFF2E3;
}

.completed {
  background-color: #E2E8E6;
  color: #074230;
}
</style>
